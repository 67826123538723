<template>
    <div>
        <div class="search">
            <div>
                <div class="a-inline-block">
                    <label for="">月份</label>
                    <a-select v-model="onMonth"  class="ant-select-lg">
                        <a-select-option  :value="item" v-for="(item,index) in monthList" :key="index">{{item}}</a-select-option>
                    </a-select>
                </div>
                <div class="a-inline-block">
                    <label for="">关键字</label>
                    <a-input v-model="key" size="large" placeholder="请输入工号、姓名等关键字" />
                </div>
                <div class="a-inline-block">
                    <label for="">工作点</label>
                    <a-select v-model="selOpid"  class="ant-select-lg">
                        <a-select-option  :value="item.id" v-for="(item,index) in operatList" :key="index">{{item.name}}</a-select-option>
                    </a-select>
                 </div>
                <div class="a-inline-block">
                    <a-button size="large" type="primary" @click="onSearch">搜索</a-button>
                    <a-button size="large" @click="reset">重置</a-button>
                </div>
            </div>
        </div>
         <div style="text-align:right;">
                <div class="a-inline-block" @click="annexModel=true">
                    <img src="@/assets/imgs/tMenu4.png" alt="" /><label for="">导入</label>
                </div>
                <div class="a-inline-block" @click="excelout">
                    <img src="@/assets/imgs/tMenu4.png" alt=""><label for="">导出</label>
                </div>
                <!-- <a-button class="addBtn" size="large" type="primary" @click="showAllot">分配工作点</a-button> -->
                <!-- <a-button class="addBtn" size="large" type="primary" style="margin-left:15px" @click="showStatus">修改状态</a-button> -->
            </div>
        <a-table :row-selection="rowSelection" :scroll="{ x: 2000}" :columns="columns" :data-source="data" class="table" :pagination="pagination">
            <!-- <template slot="Status" slot-scope="record">
                <a href="javascript:;" @click="showStatus(record)"> {{record.status}}</a>
            </template> -->
            <span slot="num" slot-scope="text, record, index">
                {{(pagination.current-1)*pagination.pageSize+parseInt(index)+1}}
            </span>
            <template slot="Action" slot-scope="text,record">
                <!-- <a class="action" href="javascript:;" @click="showDetail(record.id)">查看</a> -->
                <a class="action" href="javascript:;" @click="Edit(record.id)">编辑</a>
                <!-- <a-popconfirm title="确定要删除吗?" @confirm="() => onDelete(record.id)">
                    <a class="action del" href="javascript:;">删除</a>
                </a-popconfirm>  -->
            </template>
        </a-table>
        <!-- <a-modal v-model="showAllotModal" :centered="true" title="分配工作点"  @ok="setAllot">
            <div class="addBox">
                <div class="add_title">请选择工作点</div>
                <a-select v-model="onAllot" style="width:300px" class="ant-select-lg">
                    <a-select-option  :value="item.id" v-for="(item,index) in operatList" :key="index">{{item.oname}}</a-select-option>
                </a-select>
            </div>
        </a-modal>
        <a-modal v-model="showStatusModal" :centered="true" title="修改状态"  @ok="setStatus">
            <div class="addBox">
                <div class="add_title" style="margin-bottom:30px">请选择状态</div>
                  <a-select v-model="onStatus" class="ant-select-lg" style="width: 200px;">
                        <a-select-option :value="1">在职</a-select-option>
                        <a-select-option :value="2">离职</a-select-option>
                </a-select>
            </div>
        </a-modal> -->
        <a-modal v-model="annexModel" :centered="true" :footer="null" title="导入">
        <div>
            请先下载模板，按格式修改后导入
            <a-button @click="downExl()">下载模板</a-button>
        </div>
        <div>
        <label for="annex" class="annexBtn">
          <img src="@/assets/imgs/ico_daoru.png" alt="" /> <a-button @click="$refs.annexFile.click()">导入</a-button></label>
        <input
          type="file"
          id="annex"
          style="display: none"
          ref="annexFile"
          @change="annexUpload($event)"
        />
      </div>
    </a-modal>
    </div>
</template>
<script>
import {requestXml,ajaxUrl,fileUpload} from '../../../assets/js/request';
export default {
    data() {
        return {
            rowSelection:{
                    onSelect: (record, selected, selectedRows) => {
                        console.log(record, selected, selectedRows);
                        this.selectedRows=selectedRows;
                    },
                    onSelectAll: (selected, selectedRows, changeRows) => {
                        console.log(selected, selectedRows, changeRows);
                        this.selectedRows=selectedRows;

                    },
                },
            key: "",
            selOpid:'', //搜索-工作点
            selStatus:'', //搜索-技能
            selGreen:'', //搜索-绿化证
            selcleaning:'',//搜索-保洁证
            columns:[
                { title: '序号', dataIndex: 'num', width: 70, align: 'center',
                    scopedSlots: { customRender: 'num' },
                },
                // { title: '服务人员编号',align:'center', dataIndex: 'workerid', key: '11'},
                { title: '姓名',align:'center', dataIndex: 'wname', key: '1'},
                { title: '工作点',align:'center', dataIndex: 'oname',width:'300', key: '2'},
                { title: '底薪',align:'center', dataIndex: 'base', key: '4'},
                { title: '出勤', align:'center',dataIndex: 'attendance', key: '12'},
                { title: '应得劳务费',align:'center', dataIndex: 'deserved_salary', key: '4'},
                { title: '实得劳务费',align:'center', dataIndex: 'actual_salary', key: '4'},
                { title: '加班', align:'center',dataIndex: 'overtime', key: '5'},
                { title: '替班', align:'center',dataIndex: 'substitute', key: '8'},
                { title: '奖金',align:'center', dataIndex: 'bonus', key: '7' },
                { title: '统筹补助',align:'center', dataIndex: 'overall', key: '7' },
                { title: '保洁证',align:'center', dataIndex: 'cleaning', key: '7' },
                { title: '绿化证',align:'center', dataIndex: 'green', key: '7' },
                { title: '工龄补助',align:'center', dataIndex: 'work_age', key: '7' },
                { title: '组长补助',align:'center', dataIndex: 'group', key: '7' },
                { title: '餐补',align:'center', dataIndex: 'meal', key: '7' },
                { title: '务餐补助',align:'center', dataIndex: 'food', key: '7' },
                { title: '交补',align:'center', dataIndex: 'traffic', key: '7' },
                { title: '工服补助',align:'center', dataIndex: 'closubsidy', key: '7' },
                { title: '节日补助',align:'center', dataIndex: 'festival', key: '7' },
                { title: '其他补助',align:'center', dataIndex: 'other', key: '7' },
                { title: '补发工资',align:'center', dataIndex: 'reissue', key: '7' },
                { title: '意外保险扣款',align:'center', dataIndex: 'accident', key: '7' },
                { title: '罚款',align:'center', dataIndex: 'fine', key: '7' },
                { title: '工服扣款',align:'center', dataIndex: 'clodeduction', key: '7' },
                { title: '其他扣款',align:'center', dataIndex: 'deduction', key: '7' },
                {
                    title: '操作',
                    key: 'operation',
                    align:'center',
                    fixed: 'right',
                    width: 100,
                    scopedSlots: { customRender: 'Action' },
                },
            ],
            sex:['','男','女'],  //性别
            status:['','在职','离职'],  //工作状态
            data:[],
            selectedRowKeys: [],
            selectedRows:'',
            // 分页
            pagination: {
                current: 1,
                total: 100,
				pageSize: 10, // 默认每页显示数量
				showSizeChanger: true, // 显示可改变每页数量
				pageSizeOptions: ['10', '20', '50', '100'], // 每页数量选项
                showQuickJumper: true,
				showTotal: total => `共 ${total} 条`, // 显示总数
                onChange: (current) => this.changePage(current), //点击当前页码
                onShowSizeChange: (current, pageSize) => {//设置每页显示数据条数，current表示当前页码，pageSize表示每页展示数据条数
                    console.log(pageSize);
                    this.showSizeChange(current, pageSize)
                },
			},
            onTemp:'',
            visible:false,
            endTime:'', //工作结束时间
            showFollow:false, //跟进人弹框
            staff:'',
            onStaff:'',
            skillid:'', //技能列表
            operatList:'', //工作点列表
            showAllotModal:false, //工作点分配窗口
            onAllot:'',  //已选分配工作点
            showStatusModal:false, //状态分配窗口
            onStatus:'', //已选状态
            monthList:'', //可选月份
            onMonth:'', //已选月份
            search:'',  //搜索条件
            annexModel:false, //导入弹框
        };
    },
    mounted: function(){
        // this.getData();
        // this.getStaff();
        this.getOperat();
        this.getMonth();
    },
    methods: {
        // 搜索
        onSearch(){
            this.pagination.current=1;
            this.getData();
        },
        reset(){
            this.key = "";
            this.selOpid = null;
            this.selStatus = null;
            this.selGreen = null;
            this.selcleaning = null;
            this.getData();
        },
        // 下载模板
        downExl(){
            console.log(JSON.stringify(this.search));
              window.open(ajaxUrl+"/jieb/Salary/download?search="+JSON.stringify(this.search));  
            // window.open(window.location.protocol+"//"+window.location.host + '/uploads/项目信息导入.xls');
        },
        // 导入
        annexUpload(e){
            fileUpload(e,(res) => {
                console.log(res);
                requestXml("/jieb/Salary/excelinput","POST",(res) => {
                console.log(res);
                this.annexModel=false;
                this.$refs.annexFile.value =''
                this.getMonth();
            },{id:res.id})
            })
        },
         // 导出
        excelout(){
            console.log(JSON.stringify(this.search));
            window.open(ajaxUrl+"/jieb/Salary/excelout?search="+JSON.stringify(this.search));  
        },
         // 获取考勤月份
        getMonth(){
            requestXml("/jieb/Salary/month","POST",(res) => {
                console.log(res);
                this.monthList=res;
                this.onMonth=res[0];
                this.getData();
            })
        },
         // 获取技能分类
        getSkillid(){
             requestXml("/jieb/Temporary/skillshow","POST",(res) => {
                this.skillid=res.list;
                // console.log(this.skillid);
                // console.log(JSON.stringify( this.skillid));
                if(this.id){
                    this.getDetail();
                }
                // console.log(this.skillid);
            },{"page":{"curpage":1,"pagesize":999999}})
        },
    

       
        // 分配工作点 窗口
        showAllot(){
            if(this.selectedRows.length<1){
                this.$message.info('请选择服务人员');
                return false;
            }else{
                this.showAllotModal=true;
            }
        },
        // 分配工作点
        setAllot(){
            if(!this.onAllot){
                this.$message.info('请选择工作点');
                return false;
            }else{
                let idArr=[];
                let sel=this.selectedRows;
                for(let i=0;i<sel.length;i++){
                    idArr.push(sel[i].id);
                }
                requestXml("/jieb/Worker/operatsedit","POST",(res) => {
               if(res=="success"){
                // this.$message.success('分配成功');
                this.showAllotModal=false;
                this.onAllot='';
                this.getData();
               }
            },{id:idArr,opid:this.onAllot})
            }
        },
        // 显示状态
        showStatus(){
              if(this.selectedRows.length<1){
                this.$message.info('请选择服务人员');
                return false;
            }else{
                this.showStatusModal=true;
            }
        },
        // 设置状态
        setStatus(){
            if(!this.onStatus){
                this.$message.info('请选择状态');
                return false;
            }else{
                let idArr=[];
                let sel=this.selectedRows;
                for(let i=0;i<sel.length;i++){
                    idArr.push(sel[i].id);
                }
                requestXml("/jieb/Worker/statusedit","POST",(res) => {
               if(res=="success"){
                // this.$message.success('分配成功');
                this.showStatusModal=false;
                this.onStatus='';
                this.getData();
               }
            },{id:idArr,status:this.onStatus})
            }
        },
          // 获取工作点
        getOperat(){
             requestXml("/jieb/Salary/operatshow","POST",(res) => {
                //  console.log('res',res);
                this.operatList = res;
            },{"page":{"curpage":1,"pagesize":999999}})
        },
        // 获取成员
        getStaff(){
            requestXml("/scrm/Staff/getList","POST",(res) => {
                this.staff=res.list;
            },{"page":{"curpage":1,"pagesize":999999}})
        },
        
        // editStatus(){
        //      requestXml("/jieb/Temporary/status","POST",(res) => {
        //         console.log(res)
        //         this.getData();
        //     },{"id": this.onTemp.id,'time': Math.round(this.endTime/1000)})
        // },
        getData() {
            requestXml("/jieb/Salary/salaryshow","POST",(res) => {
                console.log(res);
                // for(let i=0;i<res.list.length;i++){
                //     // res.list[i].sex =  this.sex[Number(res.list[i].sex)];
                //     // res.list[i].status = this.status[Number(res.list[i].status)];
                //     // res.list[i].insurance = this.insurance[Number(res.list[i].insurance)];
                //     // res.list[i].deadline = getDateTime(res.list[i].deadline);
                 
                // }
                this.pagination.total = Number(res.page.total);
                this.pagination.pageSize = Number(res.page.pagesize);
                this.data = res.list;
                this.search=res.search;
            },{key:this.key,month:this.onMonth,operat:this.selOpid,"page":{"curpage":this.pagination.current,"pagesize":this.pagination.pageSize}})
        },
        onSelectChange(selectedRowKeys) {
            console.log('selectedRowKeys changed: ', selectedRowKeys);
            this.selectedRowKeys = selectedRowKeys;
        },
        // 分页
        changePage(current){
            //current参数表示是点击当前的页码，
            console.log(current)
            this.pagination.current = current;
            this.getData(); //向后端发送请求
        },
        showSizeChange(current, pageSize) {
            console.log(current, pageSize)
            this.pagination.current = current;
             this.pagination.pageSize = pageSize;
            this.getData(); //向后端发送请求
        },
        // 查看
        showDetail(id){
            this.$router.push({
                path: "/scrm_pc/worker/workerDetail",
                query: {
                    id: id,
                }
            });
        },
        //添加工人
        //  Add(){
        //     this.$router.push({
        //         path: "/scrm_pc/worker/workerAdd",
        //     });
        // },
        // 编辑
       Edit(id){
            this.$router.push({
                path: "/scrm_pc/worker/workerPayAdd",
                query: {
                    id: id,
                }
            });
        },
        // 关闭
        // off(id){
        //     requestXml("/scrm/Task/setClose","POST",(res) => {
        //         console.log(res)
        //         this.getData();
        //     },{"id": id})
        // },
        // 删除
        onDelete(id){
            requestXml("/jieb/Worker/workerdel","POST",(res) => {
                console.log(res)
                this.getData();
            },{"id": id})
        }
    },
    
};
</script>
<style scoped>
    .search{display: flex;justify-content: space-between;}
    .addBtn{width: auto;}
    .action{display:inline-block;padding:5px 10px;margin:0 5px;background:#dceeff;border:1px solid #36a3ff;color:#36a3ff;border-radius:5px}
    .del{background:#ffdcdc;border-color:#ff4c4c;color:#ff4c4c}
    .annexBtn{display: block;margin: 20px auto;width: 200px;text-align: center;}
    .annexBtn img{display: block;width: 100px;margin: 10px auto;}
</style>

